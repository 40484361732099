import * as React from 'react';
const HomeDefault = React.lazy(() => import('./Demo/Home/Default'));
const DashboardDefault = React.lazy(() => import('./Demo/Dashboard/Default'));
const CompanyDefault = React.lazy(() => import('./Demo/Company/Default'));
const CustomerDefault = React.lazy(() => import('./Demo/Customer/Default'));
const ProductDefault = React.lazy(() => import('./Demo/Product/Default'));
const RequestDefault = React.lazy(() => import('./Demo/Request/Default'));
const ServiceDefault = React.lazy(() => import('./Demo/Service/Default'));
const PaymentDefault = React.lazy(() => import('./Demo/Payment/Default'));
const PromotionDefault = React.lazy(() => import('./Demo/Promotion/Default'));
const ContractDefault = React.lazy(() => import('./Demo/Contract/Default'));

const routes = [ 
    { path: '/home',  name: 'Home', component: HomeDefault },
    { path: '/dashboard',  name: 'Dashboard', component: DashboardDefault },
    { path: '/empresa',  name: 'Empresas', component: CompanyDefault },
    { path: '/cliente',  name: 'Clientes', component: CustomerDefault },
    { path: '/contrato',  name: 'Contratos', component: ContractDefault },
    { path: '/produto',  name: 'Produtos', component: ProductDefault },
    { path: '/pedido',  name: 'Pedidos', component: RequestDefault },
    { path: '/servico',  name: 'Servicos', component: ServiceDefault },
    { path: '/pagamento',  name: 'Receitas', component: PaymentDefault },
    { path: '/promocao',  name: 'Promocao', component: PromotionDefault },
];
export default routes;
