import * as React from 'react';
const StagePage = React.lazy(() => import('./Demo/Stage/Default'));
const InfoCompanyPage = React.lazy(() => import('./Demo/InfoCompany/Default'));
const PersonPage = React.lazy(() => import('./Demo/Person/Default'));
const SearchPage = React.lazy(() => import('./Demo/Search/Default'));
const SignUp = React.lazy(() => import('./Demo/Authentication/SignUp/Default'));
const Signin = React.lazy(() => import('./Demo/Authentication/SignIn/Default'));
const ChangePassword = React.lazy(() => import('./Demo/Authentication/ChangePassword'));
const ResetPassword = React.lazy(() => import('./Demo/Authentication/ResetPassword/ResetPassword'));
const Error = React.lazy(() => import('./Demo/Maintenance/Error'));
const OfflineUI = React.lazy(() => import('./Demo/Maintenance/OfflineUI'));
const ComingSoon = React.lazy(() => import('./Demo/Maintenance/ComingSoon'));

const route = [
    { path: '/portal', exact: true, name: 'Portal', component: StagePage },
    { path: '/portal/busca', exact: true, name: 'Busca', component: SearchPage },
    { path: '/portal/rank/bullshit', exact: true, name: 'Rank', component: StagePage },
    { path: '/portal/social/info', exact: true, name: 'Social Info', component: StagePage },
    { path: '/portal/info', exact: true, name: 'Info', component: InfoCompanyPage },
    { path: '/portal/empresa/:externalId?', exact: true, name: 'Empresa', component: InfoCompanyPage },
    { path: '/portal/pessoa', exact: true, name: 'Pessoas', component: PersonPage },
    { path: '/auth/signup', exact: true, name: 'Signup', component: SignUp },
    { path: '/auth/signin', exact: true, name: 'Signin', component: Signin },
    { path: '/auth/reset-password',  exact: true, name: 'Reset Password', component: ResetPassword},
    { path: '/auth/change-password', exact: true, name: 'Change Password', component: ChangePassword },
    { path: '/maintenance/error', exact: true, name: 'Error', component: Error },
    { path: '/maintenance/coming-soon', exact: true, name: 'Coming Soon', component: ComingSoon },
    { path: '/maintenance/offline-ui', exact: true, name: 'Offline UI', component: OfflineUI }
];
export default route;
